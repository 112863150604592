//<![CDATA[
$(document).ready(function () {
  var setElm = $(".right"),
    slideSpeed = 8000;

  setElm.each(function () {
    var self = $(this),
      selfWidth = self.innerWidth(),
      findUl = self.find("ul"),
      findLi = findUl.find("li"),
      listWidth = findLi.outerWidth(),
      listCount = findLi.length,
      loopWidth = listWidth * listCount;

    findUl.wrapAll('<div class="loopSliderWrap" />');
    var selfWrap = self.find(".loopSliderWrap");

    if (loopWidth > 600) {
      findUl.css({ width: loopWidth }).clone().appendTo(selfWrap);

      selfWrap.css({ width: loopWidth * 2 });

      function loopMove() {
        selfWrap.animate(
          { left: "-" + loopWidth + "px" },
          slideSpeed * listCount,
          "linear",
          function () {
            selfWrap.css({ left: "0" });
            loopMove();
          }
        );
      }
      loopMove();

      //			setElm.hover(function() {
      //				selfWrap.pause();
      //			}, function() {
      //				selfWrap.resume();
      //			});
    }
  });

  var setElm = $(".left"),
    slideSpeed = 8000;

  setElm.each(function () {
    var self = $(this),
      selfWidth = self.innerWidth(),
      findUl = self.find("ul"),
      findLi = findUl.find("li"),
      listWidth = findLi.outerWidth(),
      listCount = findLi.length,
      loopWidth = listWidth * listCount;

    findUl.wrapAll('<div class="loopSliderWrap" />');
    var selfWrap = self.find(".loopSliderWrap");

    if (loopWidth > 600) {
      findUl.css({ width: loopWidth }).clone().prependTo(selfWrap);
      selfWrap.css({ width: loopWidth * 2 });
      selfWrap.css({ left: "-" + loopWidth + "px" });

      function loopMove2() {
        selfWrap.animate(
          { left: "0" },
          slideSpeed * listCount,
          "linear",
          function () {
            selfWrap.css({ left: "-" + loopWidth + "px" });
            //				selfWrap.animate({left:'+' + ((loopWidth) / 2) + 'px'},slideSpeed*listCount,'linear',function(){
            //					selfWrap.css({left:'+' + ((loopWidth) / 2) + 'px'});
            //					selfWrap.stop().animate({left:'0'},slideSpeed*listCount,'liner');
            loopMove2();
          }
        );
      }
      loopMove2();

      //			setElm.hover(function() {
      //				selfWrap.pause();
      //			}, function() {
      //				selfWrap.resume();
      //			});
    }
  });
});
//]]>
